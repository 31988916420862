import React from "react";

const links = [
  { name: 'Book a demo', href: 'https://app.qrate.ai/candidate/form/172' },
  { name: 'Terms & Conditions', href: '/terms-conditions' },
  { name: 'Privacy Policy', href: '/privacy-policy' }
]

export default function Footer() {
  return (
    <>
      <div className="relative isolate overflow-hidden bg-gray-900 py-10 sm:py-10">
        <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div 
          // className="mx-auto max-w-7xl px-6 lg:px-8"
          className="mx-auto max-w-7xl px-6 lg:px-8"
        >
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div>
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">About Us</h2>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Q-rate is an innovative recruitment automation tool leveraging AI algorithms to assess candidate suitability, streamlining the evaluation process, and facilitating automatic scheduling of interviews with shortlisted individuals.
                </p>
              </div>
              <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                  {links.map((link) => (
                    <a key={link.name} href={link.href} target="_blank" rel="noreferrer">
                      {link.name} <span aria-hidden="true">&rarr;</span>
                    </a>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <div className="text-center mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-2xl font-bold tracking-tight text-white">Contact Us</h2>

                <ul className="mt-6 text-lg leading-8 text-gray-300">
                  <li><strong>Email: hello@qrate.ai</strong></li>
                  <li>91-Springboard, 2nd floor,</li>
                  <li>Opp Broadway book store,</li>
                  <li>St-Inez, Panaji</li>
                  <li>Goa 403001</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-1 bg-black">Copyright © 2023 All rights reserved to Q-rate (A product of Aviate Education pvt ltd.)</div>
    </>
  )
}