import React from 'react';

import Sec3It1 from '../assets/sec3-it1.png';
import Sec3It2 from '../assets/sec3-it2.png';
import Sec3It3 from '../assets/sec3-it3.png';

const whys = [
  {
    id: 1,
    title: "Better conversions",
    description: "Candidates recommended by Q-Rate algorithms are 4 times as likely as an average applicant to get hired !",
    imageUrl: Sec3It1
  },
  {
    id: 2,
    title: "Faster TAT",
    description: "Hiring TAT improvement of over 80% !",
    imageUrl: Sec3It2
  },
  {
    id: 1,
    title: "Incredible time savings",
    description: "Conduct 60% fewer interviews !",
    imageUrl: Sec3It3
  },
];

export default function WhyQrate() {
  return (
    <div className="sm:py-10" style={{backgroundColor: "#04223e"}}> 
    {/* <div className="bg-white sm:py-32" style={{backgroundColor: "#04223e"}}>  */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold tracking-tight text-slate-200 sm:text-4xl">WHY Q-RATE?</h2>
        
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {whys.map((post) => (
            <article key={post.id} className="text-center flex max-w-xl flex-col items-start justify-start items-center py-2 px-2 rounded-md" style={{backgroundColor: "rgb(18, 59, 107)"}}>
              <img src={post.imageUrl} alt="" className="bg-gray-50 rounded-md" />

              <div className="group relative">
                {
                  post.title && (
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-slate-200 group-hover:text-white">
                      <span className="absolute inset-0" />
                      {post.title}
                    </h3>
                  )
                }
                
                {
                  post.description && (
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-white">
                      {post.description}
                    </p>
                  )
                }

                {post.lists && post.lists.map((item) => (
                    <p className="mt-5 line-clamp-3 text-base leading-6 text-white">
                      {item}
                    </p>
                ))}
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}