import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="py-20 sm:py-20"> 
      <div className="bg-white mx-auto max-w-7xl px-6 lg:px-8 py-5 rounded-lg">
        <div className="mx-auto mb-10 max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Qrate</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Privacy Policy
          </p>
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">
            Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum
            pulvinar et feugiat blandit at. In mi viverra elit nunc.
          </p> */}
        </div>
        <div className="mx-auto text-justify text-lg leading-8 text-gray-600">
        <p>
        qrate.ai takes protecting the privacy of personal data that has been submitted to qrate.ai's platform (the "product") seriously. The qrate.ai privacy policy outlines the types of data qrate.ai collects and how qrate.ai processes and discloses that data.</p>


        <br/><p style={{fontWeight: "bolder"}}>Data collection</p>
        • Personal data provided by the user qrate.ai provides a platform for employers ("employer") to invite candidates / jobseekers to respond with their interest and audio interviews. In using the product, users may provide personally identifiable data, such as name, e-mail address, physical address, phone number, their own/client job/mandate requirements in a broad range of formats such as audio recordings, video recordings, plain text and others.<br/>
        • Temporary and permanent cookies qrate.ai uses cookie files to identify potential or existing customers for the product. By using the qrate.ai website or the qrate.ai product while having the cookie function enabled in your browser, you agree to have cookies stored on your computer. qrate.ai uses temporary stored session cookies as well as permanently stored cookies. Cookies can be removed or completely blocked. Please note that if you delete, or choose not to accept cookies from the qrate.ai, you may not be able to utilize all the features of the product.<br/>
        • Other data that gets automatically collected by using the product, you agree to get data automatically recorded. qrate.ai may use technologies such as "web beacon", which may "automatically collected data including the ip address or other device addresses / ids, web browser and/or device type, the web pages or sites that you visit just before or just after the product, the pages you view on the product, and the dates and times that you visit, access, or use the product.<br/>
        • qrate.ai use and transfer to any other app of information received from Google APIs will adhere to {" "}
          <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" style={{textDecoration: "underline"}}>Google API Services User Data Policy</a>{" "}
          , including the Limited Use requirements.


        <br/><p style={{fontWeight: "bolder"}}>Data processing</p>
        <p>qrate.ai uses your personally identifiable data in a variety of ways in the operation of the product and our business, such as:</p>
        • qrate.ai uses the collected data to operate, maintain, enhance, personalize and provide all of the features found in the product. we use email addresses to initiate contacts for administrative purposes, for customer product purposes, or in the context that you have posted the data throughout the product experience.<br />
        • qrate.ai uses the collected data to aggregate data sets in order to understand and analyze trends, interests, and preferences of the user for purposes such as functionality, feature and usability improvements.<br />


        <br/><p style={{fontWeight: "bolder"}}>Data disclosure</p>
        <p>Your personally identifiable data get disclosed in a variety of ways to a limited and clearly defined audience in order to operate our business, such as:</p>
        • The product is a platform for connecting employers with applicants they have sourced on their own.<br/>
        • qrate.ai works with third party providers for website and application development, website and phone system (ivr) hosting, transcription, data processing, and others. third parties providers may have access to your personally identifiable data. generally, qrate.ai's policy is to limit these disclosures to best possible. parties such as certain government and law enforcement agencies access to data stored by such third parties without providing notice to qrate.ai, as it might be required by law and in other cases without qrate.ai having any control over such activities.<br/>
        • qrate.ai may also disclose your data if required by india law or in the good-faith belief that such action is necessary to comply with the law or to otherwise cooperate with investigative or law enforcement activity.<br/>
        • qrate.ai reserves the right to disclose your data that we believe, in good faith, is appropriate or necessary to take precautions against liability; to protect qrate.ai and others from fraudulent, abusive, or unlawful uses or activity; to investigate and defend ourselves against any third party claims or allegations; to protect the security or integrity of the product; or to protect the rights, property, security, and/or safety of qrate.ai, our users, or others.<br/>
        • qrate.ai does not share any user data with third-party tools, including AI models.


        <br/><p style={{fontWeight: "bolder"}}>Other responsibilities</p>
        • In case of declining to share certain data with qrate.ai, qrate.ai may not be able to provide to you some of the features and functionality found in the product.<br/>
        • You are responsible for maintaining the secrecy of your unique password and account data at all times.<br/>
        • Security measures<br/>
        • qrate.ai employs certain limited physical, managerial, and technical safeguards to help improve the integrity and security of our systems and the data stored on our systems. However no physical or technical system is completely secure and provides perfect protection against attacks, flaws or human error. Therefore, we cannot and do not ensure or warrant that data you transmit to qrate.ai will not be lost or compromised. If qrate.ai learns of a security systems breach, we may attempt to notify you electronically so that you can take appropriate protective steps. qrate.ai may also post a notice through the product if a security breach occurs.<br/>
        • Affiliates in the event of merger or sale.<br/>
        • qrate.ai may now or in the future be affiliated with other companies, such as parent companies and subsidiaries (and their parent companies and subsidiaries). qrate.ai reserves the right to transfer your data to any of these affiliated companies. In the event that all or a portion of qrate.ai or its assets is acquired by or merged with a third party entity or is subject to a change of control all or a portion of the data and data in qrate.ai' systems may be one of the assets involved in such transaction, and we reserve the right, in any of these circumstances, to transfer or assign all the data that we have collected from users in connection with such merger, acquisition, sale, or other change of control, with your prior permission.<br/>


        <br/><p style={{fontWeight: "bolder"}}>Other calls</p>
        <p>We may from time to time or in future make service or survey or promotional oriented calls as deemed fit.</p>


        <br/><p style={{fontWeight: "bolder"}}>Cookies policy</p>
        <p>Last updated: July 19, 2021</p>
        <p>Aviate uses cookies on the www.qrate.ai website (the "service"). By using the service, you consent to the use of cookies.</p>
        <p>Our cookies policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the service, your choices regarding cookies and further information about cookies. our cookies policy for aviate is managed by privacy policies.</p>

        <br/><p style={{fontWeight: "bolder"}}>What are cookies?</p>
        Cookies are small pieces of text sent to your web browser by a website you visit. a cookie file is stored in your web browser and allows the service or a third-party to recognize you and make your next visit easier and the service more useful to you.<br/>
        Cookies can be "persistent" or "session" cookies. persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your web browser.<br/>

        <br/><p style={{fontWeight: "bolder"}}>How aviate uses cookies?</p>
        When you use and access the service, we may place a number of cookies files in your web browser.<br/>
        We use cookies for the following purposes:<br/>
        • To enable certain functions of the service<br/>
        • We use both session and persistent cookies on the service and we use different types of cookies to run the service.<br/>
        • Essential cookies. we may use essential cookies to authenticate users and prevent fraudulent use of user accounts.<br/>

        <br/><p style={{fontWeight: "bolder"}}>What are your choices regarding cookies?</p>
        If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser. as an european citizen, under gdpr, you have certain individual rights. you can learn more about these rights in the gdpr guide.<br/>
        Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.<br/>
        • For the chrome web browser, please visit this page from google:<br/>
        https://support.google.com/accounts/answer/32050<br/>
        • For the internet explorer web browser, please visit this page from microsoft:<br/>
        http://support.microsoft.com/kb/278835<br/>
        • For the firefox web browser, please visit this page from mozilla:<br/>
        http://support.microsoft.com/kb/278835<br/>
        • For the safari web browser, please visit this page from apple:<br/>
        https://support.apple.com/kb/ph21411?locale=en_us<br/>
        • For any other web browser, please visit your web browser's official web pages.<br/>

        <br/><p style={{fontWeight: "bolder"}}>Where can you find more information about cookies?</p>
        You can learn more about cookies and the following third-party websites:<br/>
        • allaboutcookies:<br/>
        http://www.allaboutcookies.org/<br/>
        • Network Advertising Initiative:<br/>
        http://www.networkadvertising.org/<br/>

        <br/><p style={{fontWeight: "bolder"}}>Changes and updates to this privacy policy</p>
        Please revisit this page periodically to stay aware of any changes to this privacy policy, which may be revised periodically at qrate.ai's discretion. our amended privacy policy will automatically take effect once it is made available through the product. your continued use of the product after revised privacy policy has become effective indicates that you have read, understood and agreed to the current version of the privacy policy.<br/>
        </div>
      </div>
    </div>
  )
}