import React from "react";
import Jumbo from '../components/jumbo';
import Works from '../components/works';
import WhyQrate from "../components/whyQrate";
import Clients from '../components/clients';
import CaseStudy from '../components/caseStudy';
import Working from '../components/working';

export default function Home() {
  return (
    <>
      <div id="jumbotron"><Jumbo /></div>

      <div id="how-works"><Works /></div>

      <div id="working"><Working id="working" /></div>

      <div id="why-qrate"><WhyQrate id="why-qrate" /></div>

      <div id="case-study"><CaseStudy id="case-study" /></div>

      <div id="clients"><Clients id="clients" /></div>
    </>
  );
}
