import React from 'react';

export default function Jumbo() {
  return (
    <div className="mx-auto max-w-2xl py-20 sm:py-48 lg:py-40">
      <div className="text-center">
        <h1 className="text-4xl font-bold tracking-tight text-slate-200 sm:text-6xl">
          Finding the right talent isn't fate,<br /> IT'S NAVIGATION
        </h1>
        <p className="mt-6 text-lg leading-8 text-white">
            Q-Rate is a voice-based applicant screening platform for recruiters to screen of thousands of applicants within minutes, autonomously.
            Our AI-driven proprietary algorithms recommend the best-fit candidates based on communication skills, profile fit, functional skills and psychometrics.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="https://app.qrate.ai/candidate/form/172"
            target="_blank"
            rel="noreferrer"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Book a demo <span aria-hidden="true">→</span>
          </a>
          <a href="#footer" className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-white leading-6 text-gray-900 border border-indigo-700 hover:bg-indigo-600">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
}
