import React from 'react';
import Sec1It1 from '../assets/sec1-it1.png';
import Sec1It2 from '../assets/sec1-it2.png';
import Sec1It3 from '../assets/sec1-it3.png';
import Sec1It4 from '../assets/sec1-it4.png';

const works = [
  {
    id: 1,
    lists: [
      "No need of manually calling candidates to assess oral communication skills",
      "Automated interview and rating of an applicant's oral communication skills"
    ],
    imageUrl: Sec1It1
  },
  {
    id: 2,
    lists: [
      "No more manual screening of candidate’s profile / resumes",
      "Applicants are graded on the basis of profile-fit and hiring probability"
    ],
    imageUrl: Sec1It2
  },
  {
    id: 3,
    lists: [
      "No more manual evaluation of functional and psychometric skills",
      "Functional and psychometric skills assessments are enabled via our vast library of quizzes"
    ],
    imageUrl: Sec1It3
  },
  {
    id: 3,
    lists: [
      "No more outdated spray-and-pray mechanisms for hiring",
      "Asynchronous and instant video submissions for candidates with a high selection probability"
    ],
    imageUrl: Sec1It4
  },
];

export default function Works() {
  return (
    <div className="sm:py-10" style={{backgroundColor: "#04223e"}}> 
    {/* <div className="bg-white sm:py-32" style={{backgroundColor: "#04223e"}}>  */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold tracking-tight text-slate-200 sm:text-4xl">HOW THE MAGIC WORKS?</h2>
        
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {works.map((post) => (
            <article key={post.id} className="text-center flex max-w-xl flex-col items-start justify-start items-center py-2 px-2 rounded-md" style={{backgroundColor: "rgb(18, 59, 107)"}}>
              <img src={post.imageUrl} alt="" className="bg-gray-50 rounded-md" />

              <div className="group relative">
                {
                  post.title && (
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-slate-200 group-hover:text-white">
                      <span className="absolute inset-0" />
                      {post.title}
                    </h3>
                  )
                }
                
                {
                  post.description && (
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-white">
                      {post.description}
                    </p>
                  )
                }

                {post.lists && post.lists.map((item) => (
                    <p className="mt-5 line-clamp-3 text-base leading-6 text-white">
                      {item}
                    </p>
                ))}
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}