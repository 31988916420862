import React from "react";
import Client2coms from '../assets/client-2coms.png';
import ClientByju from '../assets/client-byju.png';
import ClientDelhivery from '../assets/client-delhivery.png';
import ClientLido from '../assets/client-lido.png';
import ClientUrbanClap from '../assets/client-urbanclap.png';
import ClientVedantu from '../assets/client-vedantu.png';

export default function Clients() {
  const clients = [ClientDelhivery, ClientUrbanClap, Client2coms, ClientVedantu, ClientByju, ClientLido]
  return (
    <div className="py-24 sm:py-32" >
     {/* style={{backgroundColor: "#04223e"}}> */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold tracking-tight text-slate-200 sm:text-4xl">Our Clients</h2>
        {/* <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5"> */}
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-y-10 sm:max-w-xl sm:grid-cols-6 lg:mx-0 lg:max-w-none lg:grid-cols-6"
          >
          {clients.map((client) => (
            <img
              className="col-span-2 w-full object-contain lg:col-span-1"
              src={client}
              alt="2coms"
              style={{width: "100%"}}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
