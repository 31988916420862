import React from "react";
import platform from "../assets/platform.png";
import { FunnelIcon, MicrophoneIcon, CalendarIcon, ChatBubbleOvalLeftIcon } from '@heroicons/react/20/solid'


const features = [
  {
    name: 'Automate Profile based screening',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: FunnelIcon,
  },
  {
    name: 'Automate Communication screening',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: MicrophoneIcon,
  },
  {
    name: 'Automate Interview scheduling',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    icon: CalendarIcon,
  },
  {
    name: 'Automate notifications and reminders',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    icon: ChatBubbleOvalLeftIcon,
  },
]

export default function Working() {
  return (
    <div className="overflow-hidden py-24 sm:py-32" style={{backgroundColor: "#04223e"}}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Bring Efficiency with</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-slate-200 sm:text-4xl">Q-Rate</p>
              <p className="mt-6 text-lg leading-8 text-white">
                With the power of automation make your recruitment pipeline more effiencent. Find amazing candidates
                with little to no manual efforts.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-slate-200">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    {/* <dd className="inline text-gray">{feature.description}</dd> */}
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            // src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
            src={platform}
            alt="Product screenshot"
            // className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            style={{width: "100%"}}
            // width={2432}
            // height={1442}
          />
        </div>
      </div>
    </div>
  )
}

