import React from "react";
import ClientByju from '../assets/byjus_alt.png';
import ClientVedantu from '../assets/vedantu_alt.png';

const cases = [
  {
    "id": 1,
    "name": "Byju's",
    "logo": ClientByju,
    "description": <span>Candidates recommended by Q-Rate were almost <strong>6 times</strong> as likely as an average applicant to get hired by Byju's.</span>,
    "stats": [
      { id: 1, name: 'Decrease in Interviews to Offer Ratio', value: '83.83%' },
      { id: 2, name: 'Fewer interviews conducted', value: '62%' },
      { id: 3, name: 'Rejection Accuracy', value: '100%' },
    ]
  },
  {
    "id": 2,
    "name": "Vedantu",
    "logo": ClientVedantu,
    "description": <span>Candidates recommended by Q-Rate were almost <strong>4 times</strong> as likely as an average applicant to get hired by Vedantu.</span>,
    "stats": [
      { id: 1, name: 'Decrease in Interviews to Offer Ratio', value: '73.89%' },
      { id: 2, name: 'Fewer interviews conducted', value: '74%' },
      { id: 3, name: 'Rejection Accuracy', value: '100%' },
    ]
  }
];

// Candidates recommended by Q-Rate were almost 4 times as likely as an average applicant to get hired by Vedantu.
// 74% fewer interviews conducted.

// Interview per offer reduced from 11.11 to 2.9.
// 100% Rejection Accuracy.

export default function CaseStudy() {

  return (
    <>
    <h2 className="text-center text-3xl mb-10 font-bold tracking-tight text-slate-200 sm:text-4xl">Case Study</h2>
    { cases.map((cas) => (
      <div className="text-center bg-white py-10 sm:py-10 mb-5 rounded-md">
        <img
          className="col-span-2 w-full object-contain lg:col-span-1"
          src={cas.logo}
          alt="2coms"
          style={{height: "50px"}}
        />
        <p className="text-base leading-7 text-gray-600 mb-10 mt-5">
          {cas.description}
        </p>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
            {cas.stats.map((stat) => (
              <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>))
    }
    </>
  );
}
